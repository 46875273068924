<template>
	<div class="main">
		<nav-bar />

		<div class="body">
			<slot />
		</div>

		<app-floater v-if="pageHasFloater" />
		<lazy-user-prompt/>
		<footer-bar />
	</div>
</template>

<script setup lang="ts">
// Imports
import type { RouteRecordName } from 'vue-router';
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useHead } from '#imports';

const route = useRoute();

useHead({
	title: 'Outfit7 Neo',
  link: [
    {
      rel: 'canonical',
      href: 'https://outfit7neo.com' + route.path,
    },
  ],
	meta: [
		{ name: 'description', content: 'We make great games for everyone!' },
		{ name: 'theme-color', content: '#333333' },
		{ name: 'apple-mobile-web-app-capable', content: 'yes' },
		{ name: 'apple-mobile-web-app-status-bar-style', content: 'black-translucent' },
		{ name: 'og:title', content: 'Outfit7 Neo' },
		{ name: 'og:description', content: 'We make great games for everyone!' },
		// { name: 'og:imageog:url', content: 'https://cdn.outfit7.com/web/transfered/HDS.png' },
		{ name: 'og:url', content: 'www.outfit7neo.com' }
	]
});

// Computed values
const routeName = computed(() => {
	return route.name ?? '';
});

const pageHasFloater = computed(() => {
	const noFloaterPageNames: RouteRecordName[] = ['privacy', 'legal', 'credits'];
	return !noFloaterPageNames.includes(routeName.value);
});
</script>

<style scoped lang="scss">
@import '~/assets/design';

.main {
	position: relative;
	display: block;
	width: 100%;
	min-width: $size-content-width-min;
	min-height: 100%;
	padding-top: 10px;
	// margin to account for the fixed header height
	margin: 70px auto 0;
	overflow-x: hidden;
	background: #151515;

	.body {
		height: 100%;
		padding: env(safe-area-inset-top) 0 0 0 !important;
		margin: -2px auto 0;
		background: #151515;
	}
}
</style>
