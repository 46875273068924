<template>
	<a
		href="https://o7neo.co/ml-gl-web-hds-floater"
		target="_blank"
		rel="noopener noreferrer"
		@click="trackEvent('click', { props: { category: 'floater', label: 'Mythic Legends' } })"
	>
		<div class="floater" >
			<svg class="button-pos2 button-blur6 hide" width="277" height="100" viewBox="0 0 277 100" xmlns="http://www.w3.org/2000/svg">
				<path
					class="morph"
					id="path"
					d="M98 34L71.5 17L57 37.5L23 21L40.5 40L11.5 48L38.5 53L26.5 74.5L57 60.5L54.5 81L95.5 60.5L113 74.5L129.5 61.5L158 82.5L185.5 59L200.5 79L211.5 61.5L242.5 82.5L239 62L270.5 61.5L237 43L251.5 21L221.5 36L207 21L177.5 36L161 18L138 34L115 23.5L98 34Z"
					fill="#ffecd2"
					stroke="#ffecd2"
					stroke-width="1"
				/>
			</svg>
			<img class="play-button button-pos" src="~/assets/images/button.svg" />
			<img class="button-characters" src="~/assets/images/char-bg.png" />
		</div>
	</a>
</template>

<script setup lang="ts">
// Imports
import anime from 'animejs';
import { useNuxtApp } from '#imports';
import { onMounted } from 'vue';
const { $event } = useNuxtApp();

// Lifecycle hooks
onMounted(() => {
	buttonAnimationInit();
});

const trackEvent = $event;

// Functions
function buttonAnimationInit() {
	// Do not start animation on safari desktop browsers
	const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
	const isDesktop = window.innerWidth > 768;
	if (isSafari && isDesktop) return;

	const shapes = [
		{
			d: 'M177.5 36L161 18L138 34L115 23L98 34L71.5 16.5L57 37L23 21L40.5 40L9.5 48L38.5 53L26.5 74.5L57 60.5L54.5 81L95.5 60.5L113 74.5L129.5 61.5L158 83L185.5 59L200.5 79L211.5 61.5L242.5 82.5L239 62L271 61.5L237.5 42.5L251.5 21L221.5 36L207 21L177.5 36Z'
		},
		{
			d: 'M129 32L115.5 18.5L79 33L63.5 21L49 35L18 22L27 46L1.5 55.5L36.5 56.5L24 73L67.5 58.5L74.5 76.5L101.5 58.5L115.5 78L146.5 60.5L166.5 75L182 58.5L207.5 78L217 63.5L244 74L233 48.5L259 38L235 36L242.5 21L218 36L199.5 18.5L178 33L156.5 22.5L129 32Z'
		}
	];

	anime({
		targets: '.morph',
		d: [{ value: shapes[1].d }, { value: shapes[0].d }],
		easing: 'linear',
		duration: 6000,
		loop: true
	});
}
</script>

<style scoped lang="scss">
.floater {
	position: fixed;
	display: flex;
	z-index: 100;
	align-items: center;
	justify-content: center;
	bottom: 60px;
	left: 50%;
	transform: translateY(-50%);

	@media screen and (min-width: 550px) {
		bottom: 80px;
	}
	@media screen and (min-width: 992px) {
		left: initial;
		right: 200px;
	}

	.button-pos {
		position: absolute;
		padding: 5px 0 0 0;
		z-index: 99;
	}

	.button-pos2 {
		position: absolute;
		padding: 0 0 0 0;
		z-index: 98;
	}

	.button-blur6 {
		filter: blur(11px);
	}

	.hide {
		opacity: 1;
		transition: linear 1s;
	}

	.button-characters {
		position: absolute;
		z-index: 98;
		bottom: -14px;
		left: -180px;
	}

	.play-button {
		animation: 3s pulse infinite alternate linear;
	}
}
@keyframes pulse {
	0% {
		filter: drop-shadow(0px 0px 2px rgba(255, 236, 210, 0.5));
	}
	50% {
		filter: drop-shadow(0px 0px 6px rgba(255, 236, 210, 0.5));
	}
	100% {
		filter: drop-shadow(0px 0px 2px rgba(255, 236, 210, 0.5));
	}
}
</style>
